<script setup>
import { onMounted, ref, watch, h, shallowRef } from "vue";
import MethodService from "../service/MethodService";
import { useRoute } from "vue-router";
import toastr from "toastr";
import { langWeb } from "@/stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase()); // đa ngôn ngữ cho thông báo swal, toastr
import PageHeader from "../components/page-header.vue";
import vi from "element-plus/lib/locale/lang/vi";
import en from "element-plus/lib/locale/lang/en";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { datePicker } from "../stores/datePicker";
const storeDatePicker = datePicker();
import { timeRange } from "../stores/timeRange";
const storeTimeRange = timeRange();
import { changeEdit } from "../stores/changeEdit";
const storeChangeEdit = changeEdit();
import { isHaveDataDashboard } from "../stores/HaveDataDashboard";
import { onBeforeMount } from "vue";
const storeIsHaveDataDashboard = isHaveDataDashboard();
const route = useRoute();
const rolesAccount = ref("");
const valueDate = ref(storeDatePicker.showDatePicker);
const date1 = ref(0);
const datetimerangepicker = ref(null);
const customPrefix = shallowRef({
  render() {
    return h("p", "");
  },
});
const { locale } = useI18n();
const toggleHamburgerMenu = () => {
  var windowSize = document.documentElement.clientWidth;

  if (windowSize > 767) {
    document.querySelector(".hamburger-icon").classList.toggle("open");
  }

  //For collapse horizontal menu
  if (document.documentElement.getAttribute("data-layout") === "horizontal") {
    document.body.classList.contains("menu")
      ? document.body.classList.remove("menu")
      : document.body.classList.add("menu");
  }

  //For collapse vertical menu
  if (document.documentElement.getAttribute("data-layout") === "vertical") {
    if (windowSize > 1024) {
      document.body.classList.remove("vertical-sidebar-enable");
      document.documentElement.getAttribute("data-sidebar-size") == "lg"
        ? document.documentElement.setAttribute("data-sidebar-size", "sm")
        : document.documentElement.setAttribute("data-sidebar-size", "lg");
    } else if (windowSize <= 1024) {
      document.body.classList.add("vertical-sidebar-enable");
      document.documentElement.setAttribute("data-sidebar-size", "lg");
    }
  }
};

const changeEditSetting = () => {
  storeChangeEdit.changeSettingEdit();
};
const reloadData = () => {
  location.reload();
};

const calendarChange = (date) => {
  date1.value = date;
};

const getMonthFull = (date) => {
  let month = "";
  let temp = date.getMonth() + 1;
  if (temp < 10) {
    month = "0" + temp;
  } else month = temp;
  return month;
};
const getDateFull = (date) => {
  let dateFull = "";
  let temp = date.getDate();
  if (temp < 10) {
    dateFull = "0" + temp;
  } else dateFull = temp;
  return dateFull;
};
const setDateToStore = () => {
  if (!date1.value) return false;
  if (storeTimeRange.showTimeRange > 0) {
    const nextDay = MethodService.addDays(
      new Date(date1.value[0]),
      storeTimeRange.showTimeRange - 1
    );
    const prevDay = MethodService.subtractDays(
      new Date(date1.value[0]),
      storeTimeRange.showTimeRange - 1
    );
    if (
      date1.value[1].getTime() > nextDay.getTime() ||
      date1.value[0].getTime() < prevDay.getTime()
    ) {
      toastr.warning(
        `${t("toastr_wrong_select_date")} ${
          storeTimeRange.showTimeRange
        } ${t("toastr_date")}`
      );
      valueDate.value = storeDatePicker.showDatePicker;
      datetimerangepicker.value.handleOpen();
      return;
    }
  }

  localStorage.setItem("dateSelect", valueDate.value);
  storeDatePicker.setDatePicker(valueDate.value);
  const urlParams = window.location.search.slice(1);
  if (urlParams) {
    const searchParams = new URLSearchParams(urlParams);
    let startDate = searchParams.get("start_date");
    let stopDate = searchParams.get("stop_date");
    let macId = searchParams.get("mac");
    if (startDate && stopDate && macId) {
      let start =
        new Date(storeDatePicker.showDatePicker[0]).getFullYear() +
        "-" +
        getMonthFull(new Date(storeDatePicker.showDatePicker[0])) +
        "-" +
        getDateFull(new Date(storeDatePicker.showDatePicker[0]));
      let stop =
        new Date(storeDatePicker.showDatePicker[1]).getFullYear() +
        "-" +
        getMonthFull(new Date(storeDatePicker.showDatePicker[1])) +
        "-" +
        getDateFull(new Date(storeDatePicker.showDatePicker[1]));

      const location =
        window.location.origin +
        "/customers/dashboard-timespan?start_date=" +
        start +
        "&stop_date=" +
        stop +
        "&mac=" +
        macId;
      window.history.pushState("", "", location);
    }
  }
};
const clickDownload = () => {
  document.querySelector(".button-dashboard").click();
};
const disbaleDate = (time) => {
  return time.getTime() >= new Date().getTime();
};
watch(
  () => storeDatePicker.showDatePicker,
  (newDate) => {
    setTimeout(() => {
      const urlParams = window.location.search.slice(1);
      if (urlParams) {
        const searchParams = new URLSearchParams(urlParams);
        let startDate = searchParams.get("start_date");
        let stopDate = searchParams.get("stop_date");
        if (startDate && stopDate) {
          valueDate.value = [
            new Date(startDate).toISOString(),
            new Date(stopDate).toISOString(),
          ];
        }
      } else {
        valueDate.value = newDate;
      }
    }, 1000);
  }
);
watch(
  () => storeIsHaveDataDashboard.showDownloadData,
  (newData) => {
    if (newData) {
      document.querySelector(".img-download").classList.add("hide");
      document.querySelector(".i-download").classList.remove("hide");
    } else {
      document.querySelector(".img-download").classList.remove("hide");
      document.querySelector(".i-download").classList.add("hide");
    }
  }
);
watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toUpperCase();
    locale.value = newLang.toLowerCase(); // Update i18n locale
  }
);
onBeforeMount(() => {
  const language = localStorage.getItem("language");
  if (language === "vi") {
    lang_web.changeLangVI();
    locale.value = 'vi'; // Update i18n locale
  } else {
    lang_web.changeLangEN();
    locale.value = 'en'; // Update i18n locale
  }
});
onMounted(() => {
  document.addEventListener("scroll", function () {
    var pageTopbar = document.getElementById("page-topbar");
    if (pageTopbar) {
      document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50
        ? pageTopbar.classList.add("topbar-shadow")
        : pageTopbar.classList.remove("topbar-shadow");
    }
  });
  if (document.getElementById("topnav-hamburger-icon"))
    document
      .getElementById("topnav-hamburger-icon")
      .addEventListener("click", toggleHamburgerMenu);

  const responseAccount = JSON.parse(localStorage.getItem("userInfo"));
  rolesAccount.value = responseAccount.result.roles;
});
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
            id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <img src="../assets/icon/menu.svg" alt="menu" />
            </span>
          </button>
          <PageHeader
            :items="route.meta.breadcrumb.vi"
            v-if="lang_web.showLang == 'vi'"
          />
          <PageHeader :items="route.meta.breadcrumb.en" v-else />
        </div>
        <div class="d-flex align-items-center">
          <!-- <div class="dropdown d-none topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="bx bx-search fs-22"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div> -->
          <div class="nav-date-picker">
            <div class="date-picker-item">
              <div class="date-picker-item__text">
                <p class="from-date">
                  {{ $t("t-from-date") }}
                </p>
                <img
                  src="../assets/icon/calendar-minus.svg"
                  alt="calendar"
                  class="calendar"
                />
                <p class="from-date">
                  {{ $t("t-to-date") }}
                </p>
              </div>

              <el-config-provider :locale="locale === 'vi' ? vi : en">
                <el-date-picker
                  v-model="valueDate"
                  ref="datetimerangepicker"
                  type="datetimerange"
                  class="nav-date-picker__item"
                  :prefix-icon="customPrefix"
                  :clearable="false"
                  range-separator=""
                  :start-placeholder="`${$t('t-start-date')}`"
                  :end-placeholder="`${$t('t-end-date')}`"
                  format="DD/MM/YYYY"
                  @change="setDateToStore"
                  @calendar-change="calendarChange"
                  :disabled-date="disbaleDate"
                />
              </el-config-provider>
            </div>

            <!-- <div class="date-picker-item">
              <p>Từ ngày</p>
              <el-config-provider :locale="vi">
                <el-date-picker
                  class="nav-date-picker__item"
                  v-model="valueDate[0]"
                  type="datetime"
                  :clearable="false"
                  :prefix-icon="customPrefix"
                  :placeholder="`${$t('t-start-date')}`"
                  format="DD/MM/YYYY"
                  :disabled-date="disbaleDatePrev"
                  @change="setDateToStore"
                />
              </el-config-provider>
            </div> -->
            <!-- <img
              src="../assets/icon/calendar-minus.svg"
              alt="calendar"
              class="calendar"
            /> -->
            <!-- <div class="date-picker-item">
              <p>Đến ngày</p>
              <el-config-provider :locale="vi">
                <el-date-picker
                  class="nav-date-picker__item"
                  v-model="valueDate[1]"
                  type="datetime"
                  :clearable="false"
                  :prefix-icon="customPrefix"
                  :placeholder="`${$t('t-end-date')}`"
                  format="DD/MM/YYYY"
                  :disabled-date="disbaleDateNext"
                  @change="setDateToStore"
                />
              </el-config-provider>
            </div> -->
          </div>

          <button
            style="margin-left: 10px"
            type="button"
            v-show="storeIsHaveDataDashboard.showIsHaveDataDashboard"
            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle btn-edit"
            @click="clickDownload()"
            v-b-popover.hover.left="$t('t-download')"
          >
            <img
              src="../assets/icon/download-dashboard.svg"
              alt="download"
              class="img-download"
            />
            <i class="ri-loader-3-line i-download hide"></i>
          </button>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button
              type="button"
              v-if="storeChangeEdit.showEdit === false"
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle btn-edit"
              @click="reloadData"
              v-b-popover.hover.left="$t('t-reload')"
            >
              <i class="ri-refresh-line fs-22"></i>
            </button>
            <div class="button-setting" v-if="rolesAccount?.includes('Admin')">
              <button
                type="button"
                @click="changeEditSetting"
                v-b-popover.hover.left="$t('t-edit')"
              >
                <i class="ri-settings-4-line fs-22"></i>
              </button>
              <div
                class="btn-background"
                v-if="storeChangeEdit.showEdit === true"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.img-download {
  height: 22px;
  width: 22px;
}
.i-download {
  font-size: 24px;
  animation: xoayvong 2s linear 0s infinite;
}
.navbar-header img.calendar {
  margin: 0 35px;
  position: absolute;
  top: 5px;
  z-index: 1;
  left: 56px;
}
.button-setting {
  button {
    background-color: transparent;
    border: none;
  }
  position: relative;
  margin-left: 10px;
}
.nav-date-picker {
  display: flex;
  align-items: center;
  // position: relative;
}
.date-picker-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  &__text {
    display: flex;
    justify-content: space-between;
    width: 204px;
    position: relative;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a4a4a4;
    margin-bottom: 0;
  }
}
:deep .date-picker-item .el-range-input {
  color: #494949;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
:deep .date-picker-item .el-range-input:first-of-type {
  color: #407bff !important;
}
:deep .date-picker-item .el-input__prefix {
  display: none;
}
:deep .date-picker-item .el-input__wrapper {
  padding: 0;
}
:deep .nav-date-picker__item.el-input {
  width: 100%;
}
:deep .nav-date-picker__item .el-input__wrapper {
  box-shadow: none;
  height: 20px;
}
:deep .el-date-editor.el-input,
:deep .el-date-editor.el-input__wrapper {
  height: 20px;
  box-shadow: none;
  width: 295px;
}
</style>
